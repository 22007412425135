import React from "react";
import { connect } from "react-redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  FormErrors,
  formValueSelector,
} from "redux-form";
import TextInput from "./FormComponents/TextInput";
import { IProfile } from "../models/profile";
import { RootState } from "../store";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";

interface PersonalCabinetPasswordFormProps extends InjectedFormProps<IProfile> {
  t: TFunction;
  display?: string,
  flexDirection?: any;
}

  const validatePassword = (t, password) => {
    const minLength = 8;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const digit = /\d/;
    const allowedSpecials = /^[A-Za-z0-9!@#$%^&*()_\-+=;:,./?\\|~{}]+$/;
    const forbiddenChars = /[а-яА-ЯёЁ\s]/;

    switch (true) {
      case password.length === 0:
        return "";
      case !allowedSpecials.test(password) || forbiddenChars.test(password):
        return t("Password contains invalid characters");
      case !uppercase.test(password):
        return t("Password must contain at least one uppercase letter");
      case !lowercase.test(password):
        return t("Password must contain at least one lowercase letter");
      case !digit.test(password):
        return t("Password must contain at least one digit");
      case password.length < minLength:
        return t("Password must be at least 8 characters");
      default:
        return "";
    }
  };

  const validateRepeatPassword = (t, password, repeatPassword) => {
    if (repeatPassword !== password) {
      return t("Passwords do not match");
    }
    return "";
  };

class PersonalCabinetPasswordForm extends React.Component<PersonalCabinetPasswordFormProps> {

  state = {
    password: "",
    repeatPassword: "",
    passwordError: "",
    repeatPasswordError: "",
  };

  handleChange = (t, event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (name === "password") {
        const passwordError = validatePassword(t, value);
        this.setState({ passwordError });
      } else if (name === "repeatpassword") {
        const repeatPasswordError = validateRepeatPassword(t, this.state.password, value);
        this.setState({ repeatPasswordError });
      }
    });
  };

  render() {
    const { t } = this.props;

    let textInputClassName =
      "personal-cabinet-patient-styles_text-input edit-mode";

    return (
      <div className="personal-cabinet-doctor-form_main personal-cabinet-patient-form_main">
        <div
          style={{
            display: this.props.display,
            flexDirection: this.props.flexDirection,
          }}
          className="_row"
        >
          <div className="_block0">
            <div style={{ width: "118px" }}></div>
          </div>
          <div className="_block1">
            <Field
              _className={textInputClassName}
              name="oldpassword"
              placeholder={t("Old password")}
              type="password"
              component={TextInput}
            />
          </div>

          <div className="_block2">
            <Field
              _className={textInputClassName}
              name="password"
              placeholder={t("New password")}
              type="password"
              component={TextInput}
              value={this.state.password}
              onChange={(e) => this.handleChange(t, { target: { name: "password", value: e.target.value } })}

            />
            {this.state.passwordError && <div className="password-error">{this.state.passwordError}</div>}
            <Field
              _className={textInputClassName}
              name="repeatpassword"
              placeholder={t("Repeat password")}
              type="password"
              component={TextInput}
              value={this.state.repeatPassword}
              onChange={(e) => this.handleChange(t, { target: { name: "repeatpassword", value: e.target.value } })}
            />
            {this.state.repeatPasswordError && <div className="password-error">{this.state.repeatPasswordError}</div>}
       </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    initialValues: {},
  };
};

const validate = (
  values: IProfile,
  props: PersonalCabinetPasswordFormProps & { t: TFunction }
): FormErrors<IProfile> => {
  const errors: FormErrors<IProfile> = {};
  const { t } = props;
  if (!values.password) {
    errors.password = t("Field couldn't be empty");
  }
  if (!values.repeatpassword) {
    errors.repeatpassword = t("Field couldn't be empty");
  }
  if (!values.oldpassword) {
    errors.oldpassword = t("Field couldn't be empty");
  }
  if (values.password && validatePassword(t, values.password)) {
    errors.password = 'error'
  }
  if (values.repeatpassword && validateRepeatPassword(t, values.password, values.repeatpassword)) {
    errors.repeatpassword = 'error'
  }
  return errors;
};

const form = reduxForm<IProfile, any>({
  form: "PersonalCabinetPasswordForm",
  validate,
})(PersonalCabinetPasswordForm);

export default connect(mapStateToProps)(withTranslation()(form));
