import { i18n, TFunction } from "i18next";
import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { CLINIC_LIST, getClinic, getClinicList } from "../../actions/clinic-action";
import { deletePatientClinic, getPatientMyClinicList, PATIENT_MY_CLINIC, savePatientClinic, _getPatientClinic } from "../../actions/patient-clinic-action";
import * as env from '../../app.json';
import { IClinic } from "../../models/clinic";
import { IClinicFilter } from "../../models/clinic-filter";
import { IDictionary } from "../../models/dictionary";
import { PagingOptions } from '../../models/paging-options';
import { IPatientClinic } from "../../models/patient-clinic";
import { IAuthState } from "../../states/auth-state";
import { IDictionaryState } from '../../states/dictionary-state';
import { RootState } from "../../store";
import FiltersComponentContainer from './../Filters/Filters';
import QuickAccessComponentContainer from './../QuickAccess/QuickAccess';
import ClinicsRatings from './../Ratings/ClinicsRatings';
import './Clinics.scss';
import './Filter.scss';
import history from "./../../history";
import Pagination from "../Doctors/Pagination";
import { IClinicState } from "../../states/clinic-state";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { getDoctorList } from "../../actions/doctor-action";
import { IDoctorState } from "../../states/doctor-state";
import { IDoctorFilter } from "../../models/doctor-filter";
import { showAlert, showConfirm } from "../Dialogs";
import DoctorsList from './DoctorsList';
import Liked from '@material-ui/icons/FavoriteBorder';
import Like from '@material-ui/icons/Favorite';
import ClinicItemComponent from './ClinicItem';
import { ILoadingState, LoadingSubject } from "../../states/loading-state";
import { stat } from "fs";
import { LoadingCenter } from "../Loading/Loading";
import Breadcrumbs from './../Breadcrumbs/Breadcrumbs';
import close2 from "./../../images/close2.svg";
import { getSelfClinicsList } from "../../actions/dictionary-actions";

type FilterTypes = 'CLINIC_TYPE' | 'CITY' | 'DISTRICT' | 'SORTING' | 'METRO' | 'NAME';
interface MyProps {
    getClinicList: (
        filter: IClinicFilter | undefined,
        page: number,
    ) => void;
    _clinicList: (clinics: IClinic[]) => {
        type: typeof CLINIC_LIST,
        clinics: IClinic[]
    }
    getPatientMyClinicList: (
        filter: IClinicFilter | undefined,
        page: number
    ) => void;
    _getPatientClinic: (patientClinic: IPatientClinic) => {
        type: typeof PATIENT_MY_CLINIC,
        patientClinic: IPatientClinic
    };
    deletePatientClinic: (
        id: number,
    ) => void;
    _dictionaryList: () => void;

    savePatientClinic: (
        patientClinic: IPatientClinic,
    ) => void;

    getClinic: (
        id: number,
    ) => void;

    getDoctorList: (
        filter: IDoctorFilter | undefined,
        page: number
    ) => void;
    
    getSelfClinicsList: (
        filter: any
    ) => void;

    auth: IAuthState;
    dictionary: IDictionaryState;
    patientClinic: any;
    clinic: IClinicState;
    loading: ILoadingState;
    location: Location;
    t: TFunction;
    i18n: i18n;
    doctor: IDoctorState;
}

interface MyState {
    pagingOptions: PagingOptions;
    filter: IClinicFilter;
    showFilters: boolean;
    showAllDesc: boolean;
}

class ClinicsComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            showFilters: false,
            pagingOptions: {
                page: 1,
                pageSize: 20,
                totalPages: 0
            },
            filter: this.getFilter(),
            showAllDesc: false,
        };
    }

    componentDidMount() {
        const { pagingOptions } = this.state;
        this.loadClinics(pagingOptions.page, this.getFilter());
    }

    getFilter = () => {
        const params = new URLSearchParams(this.props.location.search);
        return {
            name: params.get('name') || undefined,
            type: params.get('type') || undefined,
            city: params.get('city') || undefined,
            region: params.get('region') || undefined,
            metro: params.get('metro') || undefined,
            sort: params.get('sort') || undefined,
        } as IClinicFilter;
    }

    applyFilter(filter: IClinicFilter) {
        const params = Object.keys(filter).filter(p => filter[p]).map(p => `${p}=${filter[p]}`).join('&');
        history.push(this.props.location.pathname + (params ? '?' + params : ''))
    }

    setFilter = (value: any, type: FilterTypes) => {
        let filter = this.state.filter;
        switch (type) {
            case "CLINIC_TYPE":
                filter = { ...filter, type: value > 0 ? value : undefined };
                break;
            case "NAME":
                filter = { ...filter, name: !!value ? value : undefined };
                break;
            case "CITY":
                filter = { ...filter, city: value > 0 ? value : undefined };
                break;
            case "DISTRICT":
                filter = { ...filter, region: value > 0 ? value : undefined };
                break;
            case "METRO":
                filter = { ...filter, metro: value > 0 ? value : undefined };
                break;
            case "SORTING":
                filter = { ...filter, sort: value };
                const current = { ...this.getFilter(), sort: value };
                this.applyFilter(current);
                this.loadClinics(this.state.pagingOptions.page, current);
                break;
            default:
                break;
        }
        this.setState({ filter: filter });
    };

    loadClinics = (page: number, filter: IClinicFilter) => {
        const clinicType = this.props.location.pathname;
        if (clinicType === '/clinics') {
            this.props.getClinicList(filter, page);
            this.props.getPatientMyClinicList(filter, this.props.patientClinic.patientClinicsPage);
        }
        else if (clinicType === '/my-clinics') {
            this.props.getSelfClinicsList(filter)
        }
    }

    filterValues() {
        const filter = this.state.filter;
        const metroMap = this.props.dictionary.undergroundMap;
        const citiesMap = this.props.dictionary.cityMap;
        const distictsMap = this.props.dictionary.districtMap;
        const clinicTypesMap = this.props.dictionary.clinicTypeMap;
        const city = citiesMap && filter.city ? citiesMap[filter.city] : null;
        const metro = metroMap && filter.metro ? metroMap[filter.metro] : null;
        const district = distictsMap && filter.region ? distictsMap[filter.region] : null;
        const type = clinicTypesMap && filter.type ? clinicTypesMap[filter.type] : null;

        return [{ filter: "CLINIC_TYPE", value: type }, { filter: "DISTRICT", value: district }, { filter: "CITY", value: city }, { filter: "METRO", value: metro }].filter(c => !!c.value) as { filter: FilterTypes, value: IDictionary }[];
    }

    search() {
        this.setState({ showFilters: false });
        this.applyFilter(this.state.filter);
        this.loadClinics(this.state.pagingOptions.page, this.state.filter);
    }

    checkAllowSchedule = () => {
        if (!this.props.auth.userToken) {
            showAlert(this.props.t('Please Sing in'));
        }
        return !!this.props.auth.userToken;
    }

    changePageOptions = (pagingOptions: PagingOptions) => {
        this.setState({ pagingOptions: { ...pagingOptions } });
        this.loadClinics(pagingOptions.page, this.getFilter());
    }

    render() {
        const clinicType = this.props.location.pathname;
        const clinicsList = clinicType === '/clinics' ? this.props.clinic?.clinics : this.props.patientClinic?.patientClinicList;
        const { t } = this.props;
        const { showFilters } = this.state;
        const filter = this.state.filter;
        const sortClinics = [
            { name: 'By raiting', value: 0 },
            { name: 'Alphabetically', value: 1 },
        ];
        const metro = this.props.dictionary.underground || [];
        const cities = this.props.dictionary.city || [];
        const disticts = this.props.dictionary.district || [];
        const clinicTypes = this.props.dictionary.clinicType || [];
        const filterValues = this.filterValues();

        return (
            <div>
                <div className='filters extra-top-filter'>
                    <div className='filters-wrapper'>
                        <h1 className='big-title'>{t('Choose the best hospitals in your city')}</h1>
                        <div className='list-filters'>
                            <div className='list-filters__default-filters'>
                                <Form.Label className="regular-text">{t('Clinic name')}</Form.Label>
                                <Form.Control size="sm" type="text" value={filter.name} onKeyDown={(e) => { if (e.key === 'Enter') return this.search() }} onChange={(e) => this.setFilter(e.target.value, 'NAME')} />
                                <Button className='blue-btn list-filters__search' variant="primary" onClick={() => this.search()}>{t('Search')}</Button>
                            </div>
                            <span className='extra-filters__label'>
                                <span className='extra-filters__accordeon'
                                    onClick={() => this.setState({ showFilters: !this.state.showFilters })}>
                                    {t('Additional filters')} {showFilters ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </span>
                                {filterValues.length > 0 && <span>:</span>}
                                {filterValues.map((f) => {
                                    return <span className='extra-filters__result'>
                                        {f.value.name}<img onClick={() => this.setFilter(undefined, f.filter)} src={close2} />
                                    </span>
                                })}
                            </span>
                        </div>
                    </div>
                </div>

                <div className='extra-filters__wrapper'>
                    <div className='extra-filters__filters'>
                        {showFilters && <div className='extra-filters__row'>
                            <div className='extra-filters__column'>
                                <Form.Label className="regular-text">{t('Type')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.type}
                                    onChange={(e) => this.setFilter(e.target.value, 'CLINIC_TYPE')}
                                >
                                    <option value={0}>{t('Type')}</option>
                                    {clinicTypes.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                            <div className='extra-filters__column'>
                                <Form.Label className="regular-text">{t('City')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.city}
                                    onChange={(e) => this.setFilter(e.target.value, 'CITY')}
                                >
                                    <option value={0}>{t('City')}</option>
                                    {cities.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                            <div className='extra-filters__column'>
                                <Form.Label
                                    className="regular-text"
                                >{t('District')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.region}
                                    onChange={(e) => this.setFilter(e.target.value, 'DISTRICT')}
                                >
                                    <option value={0}>{t('District')}</option>
                                    {disticts.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                            <div className='extra-filters__column'>
                                <Form.Label
                                    className="regular-text"
                                >{t('Metro')}</Form.Label>
                                <Form.Control
                                    size="sm"
                                    className='extra-filters__filter'
                                    as="select"
                                    value={filter.metro}
                                    onChange={(e) => this.setFilter(e.target.value, 'METRO')}
                                >
                                    <option value={0}>{t('Metro station')}</option>
                                    {metro.map((sd) => {
                                        return <option value={sd.id}>{t(sd.name)}</option>
                                    })}
                                </Form.Control>
                            </div>
                        </div>}
                    </div>
                </div>

                <div style={{ maxWidth: '910px', margin: '0 auto 20px auto' }}><Breadcrumbs location={this.props.location} /></div>

                <div className='clinics-main-wrapper'>
                    <div className='doctors-filters'>
                        <Form.Control
                            as="select"
                            value={filter.sort}
                            onChange={(e) => this.setFilter(e.target.value, 'SORTING')}
                        >
                            {sortClinics.map((sd) => {
                                return <option value={sd.value}>{t(sd.name)}</option>
                            })}
                        </Form.Control>
                    </div>
                    {!clinicsList || !clinicsList.length && <div className='clinics-big-title no-result'>{this.props.loading.subjects.has(LoadingSubject.ClinicList) || this.props.loading.subjects.has(LoadingSubject.PatientClinicList) ? <LoadingCenter backgroundColor='#eef1f8' /> : t('No results were found')}</div>}
                    {clinicsList && clinicsList.map((clinic: any) => {

                        let _clinic = clinic;
                        let _clinicType = _clinic?.type;
                        if (_clinicType && /^\d+$/.test(_clinicType)) {
                            this.props.dictionary && this.props.dictionary?.clinicType && this.props.dictionary.clinicType.map((itm: any) => {
                                if (itm.id == _clinicType) {
                                    _clinicType = itm.name;
                                }
                            })
                        }

                        const patientClinicList = this.props.patientClinic?.patientClinicList;
                        let clinicInMyClinics = false;
                        patientClinicList && patientClinicList.length && patientClinicList.map((clinique: any) => {
                            if (clinique.id === _clinic.id) {
                                clinicInMyClinics = true;
                            }
                        })

                        return (
                            <ClinicItemComponent clinicItem={clinic} clinicType={clinicType} patientClinicList={this.props.patientClinic?.patientClinicList} />
                        )
                    })}
                    {clinicType !== "/my-clinics" && <Pagination
                        changePageOptions={this.changePageOptions}
                        start={0}
                        pagingOptions={
                            { ...this.state.pagingOptions, totalPages: this.props.clinic.totalCount }} />}
                </div>
                <QuickAccessComponentContainer />
                <FiltersComponentContainer />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    clinic: state.clinic,
    auth: state.auth,
    patientClinic: state.patientClinic,
    dictionary: state.dictionary,
    doctor: state.doctor,
    loading: state.loading,
});

const mapDispatchToProps = {
    getClinicList,
    _getPatientClinic,
    deletePatientClinic,
    getPatientMyClinicList,
    savePatientClinic,
    getClinic,
    getDoctorList,
    getSelfClinicsList
};

let ClinicsComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    ClinicsComponent
);

export default withTranslation()(ClinicsComponentContainer);