import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18next from "i18next"; 

export const showToast = (message, type = "success") => {
  const options: ToastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const text = i18next.t(message);

  switch (type) {
    case "success":
      options.style = { backgroundColor: "#03A8F5"};
      toast.success(text, options);
      break;
    case "error":
      toast.error(text, options);
      break;
    case "info":
      toast.info(text, options);
      break;
    case "warning":
      toast.warning(text, options);
      break;
    default:
      toast(text, options);
      break;
  }
};