import { Action, ActionCreator } from "redux";
import { DictionaryTypesEnum } from "../models/dictionary-types.enum";
import { IDictionary } from "../models/dictionary";
import { ThunkAction } from "redux-thunk";
import { pushAlert } from "./alert-actions";
import * as environment from "../app.json";
import { IClinic } from "../models/clinic";
import { RootState } from "../store";
import { UserRole } from "../models/user-role.enum";
import { authFetch, signOut } from "./auth-actions";
import { loadingEnd, loadingStart } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import { _getPatientClinicList } from "./patient-clinic-action";

function topDoctorData(language: string) {
  let topDoctor = environment.topDoctor;
  if (language == "az") topDoctor = environment.topDoctorAz;
  if (language == "ru") topDoctor = environment.topDoctorRu;
  return topDoctor;
}

export const GET_DICTIONARY = "GET_DICTIONARY";
interface DictionaryAction {
  type: typeof GET_DICTIONARY;
  dictionaryType: DictionaryTypesEnum;
  payload: IDictionary[];
}

export const _dictionaryList: ActionCreator<DictionaryAction> = (
  dictionaryType: DictionaryTypesEnum,
  payload: any[]
) => {
  return {
    type: GET_DICTIONARY,
    dictionaryType: dictionaryType,
    payload: payload,
  };
};

export const SAVE_DICTIONARY = "SAVE_DICTIONARY";
interface SaveDictionaryAction {
  type: typeof SAVE_DICTIONARY;
  dictionaryType: DictionaryTypesEnum;
  payload: any;
  // language: string;
}

export const _saveDictionaryList: ActionCreator<SaveDictionaryAction> = (
  dictionaryType: DictionaryTypesEnum,
  payload: any[]
  // language
) => {
  // console.log(language, "langForSave");
  // console.log(dictionaryType, "dictionaryType");
  // console.log(payload, "payloadForSave");
  return {
    type: SAVE_DICTIONARY,
    dictionaryType,
    payload,
    // language,
  };
};

export type DictionaryActionType = DictionaryAction | SaveDictionaryAction;

let langDictionary = {
  Az: new Map(),
  Ru: new Map(),
  En: new Map(),
};

export const getDictionaryList =
  (
    type: DictionaryTypesEnum,
    language: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    if (type === DictionaryTypesEnum.Clinics) {
      dispatch(getClinicsList(language));
      return;
    }
    const langCapitalize = language[0].toUpperCase() + language.slice(1);

    let langCheck;
    if (language === "az") langCheck = langDictionary[langCapitalize];
    if (language === "ru") langCheck = langDictionary[langCapitalize];
    if (language === "en") langCheck = langDictionary[langCapitalize];
    const obj = Object.fromEntries(langDictionary[langCapitalize]);

    console.log(langCheck, "langCheckAftercall");
    console.log(type, "TypesforDic");
    console.log(obj[type], "ObjMap");

    try {
      dispatch(loadingStart(LoadingSubject.Dictionaries));
      console.log("getDictionaryListStartwithout");
      //&& !langCheck[langCapitalize][type].length
      if (!obj[type]) {
        console.log("getDictionaryListStart");
        const response = await fetch(
          topDoctorData(language) + "dds/ddlist?type=" + type,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const json = await response.json();
        if (language === "az")
          langDictionary[langCapitalize].set(type, json.payload);
        if (language === "ru")
          langDictionary[langCapitalize].set(type, json.payload);
        if (language === "en")
          langDictionary[langCapitalize].set(type, json.payload);

        dispatch(_dictionaryList(type, json.payload));
      } else {
        langCheck.forEach((payload, type) => {
          if (language === "az") dispatch(_dictionaryList(type, payload));
          if (language === "ru") dispatch(_dictionaryList(type, payload));
          if (language === "en") dispatch(_dictionaryList(type, payload));
        });
      }
    } catch (e) {
      console.log("getDictionaryList exception", e);
    } finally {
      dispatch(loadingEnd(LoadingSubject.Dictionaries));
    }
  };

const getClinicsList =
  (language: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      dispatch(loadingStart(LoadingSubject.Dictionaries));
      const response = await fetch(
        topDoctorData(language) + "clinics/search?per-page=10000",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();

      dispatch(
        _dictionaryList(
          DictionaryTypesEnum.Clinics,
          json.map((c: IClinic) => {
            return {
              id: c.id,
              type: DictionaryTypesEnum.Clinics,
              name: c.name,
              description: c.description,
              active: c.active,
              metro: c.metro,
              region: c.region,
            } as IDictionary;
          })
        )
      );
    } catch (e) {
      console.log("getClinicsList exception", e);
    } finally {
      dispatch(loadingEnd(LoadingSubject.Dictionaries));
    }
  };

export const getSelfClinicsList =
  (filter: any): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const auth = getState().auth;
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl + "external/private/clinic/list",
        "POST",
        { myClinic: true, ...filter},
        { Accept: "application/json", "Content-Type": "application/json" }
      );

      if (response.status === 200) {
        const json = await response.json();
        console.log("doctor clinics", json);
        const end = response.headers.get('X-Pagination-Page-Count') === response.headers.get('X-Pagination-Current-Page');
        dispatch(_getPatientClinicList(json, 1, end));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (e) {
      console.log("getClinicsList exception", e);
    } finally {
    }
  };