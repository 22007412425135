import React, { Component } from 'react';
import Navigation from './navigation/index';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './store';
import { Provider as ReduxProvider } from 'react-redux';
import reduxWebsocket, { connect } from '@giantmachines/redux-websocket';
import './index.scss';
import MobileHomeComponent from './components/MobileVersion/MobileHome';
import chatMessageMiddleware from './middleware/chat-middleware';
import callMiddleware from './middleware/call-middleware';
import { ToastContainer } from "react-toastify";
import './i18n';

const reduxWebsocketMiddleware = reduxWebsocket({
  reconnectOnClose: false,
  serializer: (payload: any) => {
    if (payload === ">") {
      return ">";
    }
    else return JSON.stringify(payload);
  }
});

const store = createStore(reducers, applyMiddleware(thunk, reduxWebsocketMiddleware, chatMessageMiddleware(), callMiddleware()));

interface MyProps { }

interface MyState {
  windowWidth: number | string;
}

class App extends Component<MyProps, MyState> {

  constructor(props: MyProps) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    };
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  
  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    const { windowWidth } = this.state;
    return (
      <ReduxProvider store={store}>
        {windowWidth > 480 ? <Navigation /> : <MobileHomeComponent/>}
        <ToastContainer />
      </ReduxProvider>
    );
  }

}


export default App;